import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Top MERN Stack Developers | MERN Stack Development Services in India',
    metaDescription: 'Outsource MERN Stack Developer & programmers from India to develop custom web applications, progressing web apps and mobile applications at the lowest possible cost. Hire now!',
    h1: 'Hire Dedicated MERN Developers',
    h1Subtext1: 'Build progressing web apps and secure back-end using MERN stack development.',
    h1Subtext2: 'MatteCurve is the best MERN stack development company in India. We are a JavaScript company and have dedicated MERN developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire MERN stack developers within 7 days.',
    aboutSection: {
        title: 'About MERN Stack',
        subTitle: 'A javascript based framework for developing web applications.',
        paragraph1: "MERN stack is a compilation of JavaScript-based technologies used to develop dynamic web applications and hybrid mobile apps.  An acronym for the tetra combo of MongoDB, ExpressJS, React, and Node.js.",
        paragraph2: "MERN is a full-stack developer that has a broader scope and knowledge of various programming languages and stack-based frameworks. This free and open-source stack offers a quick and organized method for creating rapid prototypes for web-based applications.",
        paragraph3: `To build secure and progressing web applications using the MERN stack, A developer should be well experienced in technologies like MongoDB, Express (A Node.js Framework), React, and Node.js. They are all javascript technologies, so a MERN stack developer should be experienced in javascript with database skills. Hire MERN stack developers from MatteCurve who are experienced and skilled in developing large scale web applications using MERN stack.`,
        keyPoints: [
            "MERN is a full-stack development toolkit used to develop fast and robust web applications.",
            "All four technologies (MongoDB, Express (A Node.js Framework), React, and Node.js) working together have led to the creation of a contemporary niche for the development process.",
            "MERN stack has gained popularity because it allows developers to use the same javascript language for frontend development as well as backend development.",
            "MERN stack is a compilation of JavaScript-based technologies used to develop dynamic web applications and hybrid mobile apps.",
            "MERN can be used to build robust and maintainable solutions.",
            "MERN is a user-friendly stack that is the ideal solution for building dynamic websites and applications.",
            "MERN stack helps in building apps with the highest speeds delivered. But, everything can be accessed free of cost & that’s the best part of the MERN Stack.",
        ],
    },
    statsSection: {
        paragraph: "MERN stack application development is mostly centered around ReactJs, NodeJs & MongoDB at the core. With the ever-rising popularity of React Native, we could notice a number of amazing and popular apps in the app stores, build on this technology are Uber, Slack, Coursera & Netflix use these technologies for building their customer-focused products. Hire Dedicated MERN Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated MERN Stack Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated MERN Developers?",
        "Hire dedicated MERN Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for MERN Development?",
        paragraph: "Our dedicated developers are skilled with each component of MERN using MongoDB, ExpressJS, React JS, and Node JS for creating futuristic web applications that meet your business needs. We endeavor to build efficient web applications that are high-performing, scalable, and versatile.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
